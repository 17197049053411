@import "./../base/variables";

.desktop-design {
  display: none;
  width: 100%;
  @media (min-width: 960px) {
    display: block;
  }

  &.services {
    margin-left: 60px;
    overflow: hidden;
  }

  .service-container-wrapper {
    height: 100%;
    width: 100%;
  }

  .header-services {
    box-shadow: none !important;
    left: 0;
    position: sticky !important;
    top: 0;
    width: 100%;

    .toolbar {
      padding: 0 12px 0 28px;
    }
  }

  .service-content {
    background-color: #2a2a2a;
    max-height: 100%;
    position: relative;
    width: 25% !important;

    .lists {
      max-height: calc(100% - 64px);
      overflow-y: auto;
      position: relative;
      z-index: 999;
    }

    .services-wrapper {
      position: initial;
    }

    .single-service {
      margin-right: 0;
      position: relative;
      width: 90%;

      .right-content .status {
        min-width: 15px;
      }

      .radio {
        bottom: 0;
        left: 10px;
        position: absolute;
        top: 0;

        &:hover {
          background-color: transparent;
        }

        &.active {
          &::before {
            background-color: #fff;
            bottom: 0;
            content: "";
            left: 1px;
            opacity: 1;
            position: absolute;
            top: 0;
            width: 3px;
            z-index: 99999;
          }

          & + .label {
            .left-content p {
              color: #fff;
              font-weight: 700;
            }

            .status span {
              font-weight: 700;
            }

            .icon {
              color: #272727 !important;
            }
          }
        }
      }
    }

    .label {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-left: 28px;
      width: 100%;

      .left-content {
        color: #d9d9d9;
      }

      .right-content {
        display: block;
      }

      .status {
        align-items: center;
        display: flex;
      }

      .icon {
        color: #fff;
        font-size: 13px !important;
        font-weight: normal;
        height: 18px !important;
        margin: 0 0 0 12px;
        width: 18px !important;
      }
    }

    &.folders {
      background-color: #272727;

      .header-services {
        background-color: #202020 !important;

        .toolbar {
          color: #d9d9d9;

          .icon-button {
            color: #d9d9d9;
          }
        }
      }

      .services-wrapper {
        background-color: #272727;
      }

      .single-service {
        border-color: #272727;
        height: 50px;
        justify-content: flex-start;

        &:hover {
          background-color: #111;
        }

        .left-content {
          color: #939393;
        }

        .icon {
          border-radius: 0;
          font-size: 20px !important;
          margin-right: 10px;
        }

        .radio.active + .label .icon {
          color: #fff !important;
        }
      }

      .label {
        justify-content: flex-start;
        padding-left: 5px;
      }
    }

    &.service-types {
      background-color: #343434;

      .header-services {
        background-color: #272727 !important;

        .title .left-content {
          color: #d9d9d9;
          display: flex;

          .icon {
            border-radius: 0;
            font-size: 20px !important;
            margin: 0 10px 0 0;
          }
        }
      }

      .services-wrapper {
        background-color: #343434;

        .single-service {
          border-color: #272727;

          &:hover {
            background-color: #222;
          }

          .icon {
            color: #343434;
          }
        }
      }
    }

    &.service-plans {
      background-color: #fcfcfc;

      .header-services {
        background-color: #dcdcdc !important;

        .title {
          color: #343434;
        }

        .icon-button {
          color: #343434;
        }

        .search-bar .cancel-btn {
          color: #343434;
        }
      }

      .services-wrapper {
        background-color: #fcfcfc;

        .single-service {
          border-color: #f2f2f2;

          &:hover {
            background-color: #dbdbdb;
          }

          .left-content {
            color: #343434;
          }
        }

        .radio.active {
          &::before {
            background-color: $color-primary;
          }

          & + .label {
            .left-content p {
              color: #343434;
              font-weight: 700;
            }

            .status span {
              font-weight: 700;
            }

            .icon {
              color: #fff !important;
            }
          }
        }
      }
    }

    &.service-people {
      background-color: #fff;

      .header-services {
        background-color: #f6f6f6 !important;

        .title {
          color: #272727;
        }

        .icon-button {
          color: #272727;
        }

        .search-bar .cancel-btn {
          color: #343434;
        }
      }

      .services-wrapper {
        background-color: #fff;

        .single-service {
          border: 1px solid #f2f2f2;
          border-radius: 0;
          width: 100%;

          &:hover {
            background-color: #fcfcfc;
          }

          .label {
            align-items: flex-start;
          }

          .left-content {
            color: #343434;
            display: flex;
            flex-direction: column;
          }

          .name-position {
            display: flex;
            text-transform: none;
          }

          .name {
            font-size: 15px;
            margin-right: 6px;
          }

          .position {
            color: #c5c5c5;
            font-size: 15px;
          }

          .number {
            color: #c5c5c5;
            font-size: 13px;
            text-align: left;
          }

          &.opted-out {
            .name-position {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.logs-drawer {
  .backdrop {
    background-color: transparent !important;
  }

  .paper {
    padding: 20px;
    width: calc(50% - 56px);

    .sweet-loading {
      margin-left: -20px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;

    h3 {
      border-bottom: 3px solid $color-primary;
      color: #343434;
      font-size: 18px;
    }

    .material-icons {
      cursor: pointer;
    }
  }
}

.account-syncing-message {
  align-items: center;
  background: $color-primary;
  display: flex;
  height: 35px;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 3;

  &.info {
    background: #2166f7;
    opacity: 0.5;
  }

  .message {
    color: #fff;
    font-size: 16px;
  }

  &.warning {
    background: #eb5a46;
    padding-left: 290px;

    .message {
      font-size: 14px !important;
      a {
        color: #fff;
        text-decoration: underline !important;
      }
    }
  }

  .close-icon {
    color: #fff;
    cursor: pointer;
    position: absolute;
    left: auto;
    right: 10px;
    font-size: 18px;
  }
}

.syncing {
  .main-menu {
    /*top: 35px;*/
  }

  .page-wrapper .sub-menu {
    top: 35px !important;
  }
}

.mobile-design {
  display: block;
  @media (min-width: 960px) {
    display: none;
  }

  .header-services .toolbar {
    background-color: #2a2a2a;
  }
}
