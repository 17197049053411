@import "./../../base/variables";

.message-templates {
  background-color: #fff !important;
  box-shadow: none !important;
  margin: 0 !important;
  width: auto !important;
  padding-top: 0 !important;

  .sub-menu {
    display: none;
  }
  @media(min-width: 960px) {
    margin-left: 60px !important;
    position: sticky;
    top: 0;

    .desktop-title {
      color: #131313;
      font-size: 24px;
      margin-left: 18px;
    }

    .header-more {
      display: none;
    }

    .outer-wrapper {
      padding: 20px;
    }

    .message-tabs {
      background-color: #343434 !important;
      border-bottom: 1px solid #343434;
      left: 0;
      max-width: 100%;
      right: 0 !important;
      top: 0 !important;
      position: sticky !important;
    }

    .sub-menu {
      display: block;
      flex-grow: 1;
      position: fixed;
      bottom: 0;
      top: 0;
    }

    .tab-container {
      padding-top: 10px !important;
    }

    .template {
      border-bottom: 0;
      position: relative;

      &:after {
        content: '';
        background-color: #E1E1E1;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
      }

      .title-bar {
        padding-right: 12px;
      }

      .title {
        color: #131313;
        font-size: 14;
        font-weight: 600;
      }

      .detail {
        color: #727272;
        font-size: 12px;
      }
    }

    .inner-content-wrapper {
      flex-grow: 1;
      margin: 0 !important;
      padding-top: 23px;
      width: auto !important;
    }
  }
}