@import "./../../base/variables";

.integrations {
  padding: 20px;
  @media (min-width: 960px) {
    margin-right: 36px;
    padding: 0;
  }

  .country-white-select {
    color: #fff;

    &::before {
      border-bottom-color: #fff !important;
    }

    svg {
      color: #fff !important;
    }
  }

  .desktop {
    display: block;
  }

  .integration-card {
    background-color: #2c5fd8;
    height: 190px;
    position: relative;

    .right-icon .material-icons {
      color: #fff;
      float: right;
    }

    .right-icon {
      position: absolute;
      right: 0;
      top: 6px;
    }

    .integrations-planning-center {
      left: 15px;
      position: absolute;
      right: auto;
      top: 10px;
    }

    .account {
      bottom: 12px;
      color: #fff;
      left: 16px;
      position: absolute;
    }

    .account-title {
      opacity: 0.5;
    }
  }

  .integration-card-inactive {
    background-color: #fff;
    border: 1px solid #cccccc;
    padding: 12px 22px;

    .planning-center {
      position: relative;
    }

    .pc-details {
      color: $color-text;
      font-size: 14px;
    }
  }

  .comming-soon {
    bottom: 60px;
    left: 0;
    padding: 0 14px;
    position: absolute;
    right: 0;

    h3 {
      border-bottom: 1px solid $color-border-light;
      color: $color-text;
      font-size: $size-text;
      font-weight: 400;
      padding: 4px 12px;
      text-transform: uppercase;
      @media (max-width: 360px) {
        font-size: 13px;
      }
    }

    .logos {
      align-items: center;
      display: flex;

      img {
        max-width: 100%;
      }
    }
  }
}

.integration-page {
  background-color: #fff !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding-right: 20px;
  width: auto !important;

  .sub-menu {
    bottom: 0;
    display: none;
    position: fixed;
    top: 0;
  }

  .desktop-view {
    display: block;
    margin-left: 42px;
    margin-top: 23px;
    padding-left: 225px;
    width: 100%;
    @media (max-width: 960px) {
      margin-left: 0;
      padding-left: 10px;
    }

    .integrations {
      padding: 0;
      width: 100%;

      .title {
        border-bottom: 1px solid #bbbbbb;
      }
    }

    .cards {
      margin-bottom: 50px;
      margin-top: 15px;
    }

    .cards .single {
      border-radius: 10px;
      padding: 12px 5px 20px 15px;
      height: 215px;

      .box-title {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .material-icons {
          cursor: pointer;
        }

        .btn {
          align-items: center;
          border-radius: 4px;
          display: flex;
          height: 30px;
          justify-content: center;
          padding: 0 10px !important;
        }

        .text-primary {
          color: #16bc12 !important;
          font-weight: 600;
        }
      }

      .content {
        color: #fff;
        font-size: 13px;
        margin-bottom: 30px;
        margin-top: 25px;
        text-align: center;

        p {
          font-size: 13px;
        }
      }

      .level-two {
        font-weight: 600;
      }

      .sync-info {
        color: #fff;
        font-size: 10px;
        // opacity: 50%;
        text-align: center;
      }

      &.planning-center {
        background-color: #2166f7;
      }

      &.pastorsline-box {
        background-color: #5517c1;

        .material-icons {
          color: #fff;
        }
      }

      &.bitly-box {
        background-color: #dd5a2b;

        .material-icons {
          color: #fff;
        }
      }

      &.pastorsline-inactive {
        background-color: #dbdbdb;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }

      &.bitly-inactive {
        background-color: #dbdbdb;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        .material-icons {
          color: #fff;
        }

        .content {
          color: #828282;
          margin-top: 38px;
        }
      }
    }

    .single-box {
      align-items: center;
      border: 1px solid #bbbbbb;
      border-radius: 10px;
      display: flex;
      height: 188px;
      justify-content: center;
      width: 100%;
    }
  }

  .desktop-title {
    color: #131313;
    font-size: 24px;
    margin-bottom: 25px;
  }
  @media (min-width: 960px) {
    margin-left: 60px !important;
    position: relative;

    .header-more {
      display: none;
    }

    .sub-menu {
      display: block;
    }
  }
}

.connect-account-modal {
  .paper {
    max-width: 500px;
  }

  .backdrop {
    background-color: #fff !important;
    opacity: 0.8 !important;
  }

  .btn {
    border-radius: 3px;
    color: #fff;
    height: 26px;
    padding: 0 10px !important;
    text-transform: capitalize;
  }

  .modal-content {
    color: #828282;
    font-size: 13px;
    padding: 0 10px;
  }

  .title {
    padding: 20px 30px;
    position: relative;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .action {
    padding: 10px 30px 30px;
  }
}

.pco-modal-paper {
  width: 80vw !important;
}

.pco-content-container {
  max-height: 80vh;
  min-height: 50vh;
  @media (max-width: 960px) {
    max-height: 100vh;
  }
}
