@import "./../../base/variables";

.my-profile {
  overflow: hidden;
  .sub-menu {
    bottom: 0;
    display: none;
    position: fixed;
    top: 0;
  }

  .mobile-design {
    display: none;
  }

  .desktop-design {
    display: block;
    margin-left: 42px;
    margin-top: 23px;
    padding-left: 225px;

    .desktop-title {
      color: #131313;
      font-size: 24px;
      margin-bottom: 25px;
      margin-left: -18px;
    }

    .title {
      border-bottom: 1px solid #e1e1e1;
      color: #131313;
      font-size: 18px;
      margin-bottom: 22px;
      width: 100%;
    }

    .user-detail {
      margin-left: -15px;

      .item {
        display: flex;
      }

      .avatar {
        border-radius: 50%;
        height: 94px !important;
        min-width: 94px;
        overflow: hidden;
        position: relative;
        width: 94px !important;

        input {
          display: none;
        }

        .edit {
          align-items: center;
          background-color: #2e3fad;
          bottom: 0;
          color: #fff;
          cursor: pointer;
          display: flex;
          font-size: 12px;
          height: 22px;
          justify-content: center;
          left: 50%;
          position: absolute;
          text-align: center;
          transform: translate(-50%, 0);
          width: 100%;
        }
      }

      .input-wrapper {
        background-color: transparent;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        &.one-way {
          width: 225px;
          height: 30px;
        }

        .edit {
          color: #2e3fad;
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          margin-top: 15px;
        }

        .icon {
          background-color: #c9c9c9;
          border-radius: 50%;
          height: 19px !important;
          margin: 4px 0 0;
          width: 19px !important;

          span {
            font-size: 12px;
          }
        }

        .input {
          font-size: 16px;
          height: 2px;
          text-overflow: ellipsis;
          max-width: 350px !important;
          @media (max-width: 400px) {
            max-width: 255px !important;
          }
          @media (max-width: 350px) {
            max-width: 210px !important;
          }
        }

        .label {
          color: #676767;
          font-size: 12px;
        }

        &.select {
          .input {
            align-items: flex-end;
            background: transparent;
            display: flex;
            flex-direction: row;
          }

          svg {
            display: none;
          }
        }

        &.phone {
          align-items: center;
          display: flex;
          margin-top: 10px;
          padding-left: 13px;

          .react-tel-input {
            @media (max-width: 400px) {
              width: 205px !important;
            }
          }

          .react-tel-input input:disabled {
            cursor: auto;
          }

          input {
            border: none;
            font-size: 16px;
            text-overflow: ellipsis;
            width: 100%;
          }

          .edit {
            margin-top: 0;
          }

          .flag-dropdown {
            background-color: transparent;
            border: none;
          }
        }
      }

      .show-icon .select svg {
        display: block;
      }

      .edit-mode input {
      }

      .name {
        display: flex;
        flex-direction: column;
        padding-left: 28px;
        @media (max-width: 1100px) {
          padding-left: 12px;
        }
      }

      .contact {
        display: flex;
        flex-direction: column;
      }

      .name {
        .input-wrapper .input {
          @media (max-width: 450px) {
            max-width: 220px !important;
          }
          @media (max-width: 400px) {
            max-width: 190px !important;
          }
          @media (max-width: 350px) {
            max-width: 140px !important;
          }
        }
      }

      &.login {
        .input-wrapper .input {
          @media (max-width: 450px) {
            max-width: 255px !important;
          }
          @media (max-width: 400px) {
            max-width: 210px !important;
          }
          @media (max-width: 350px) {
            max-width: 170px !important;
          }
        }
      }
    }
    @media (max-width: 600px) {
      padding-left: 0;
      margin-left: 0;

      .desktop-title {
        display: none;
      }
    }
  }
  @media (min-width: 960px) {
    margin-left: 60px !important;
    position: relative;

    .header-more {
      display: none;
    }

    .mobile-design {
      display: none;
    }

    .sub-menu {
      display: block;
    }

    .desktop-design {
      display: block;
    }
  }
}

.user-info {
  .item {
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;

    .value {
      color: $color-text;
      font-size: $size-menu;
    }

    .label {
      color: #b5b5b5;
      font-size: $size-label;
    }
  }
}

.user-settings {
  height: 100%;

  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: #b5b5b5;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }

    &.org {
      margin-top: 50px;
    }
  }

  .change-number {
    padding: 20px;
  }

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .number-btn {
    align-items: center;
    border: 1px solid $color-border;
    border-radius: 0;
    color: $color-form-label;
    display: flex;
    font-size: $size-text;
    height: 50px;
    justify-content: center;
    padding: 0;
    width: 100%;

    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }

    &:focus {
      background-color: $color-primary;
      border-color: $color-primary;
      color: #fff;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .text-primary {
    float: right;

    .label {
      color: #067302;
      font-size: $size-label;
      font-weight: 500;
    }

    .material-icons {
      font-size: 18px;
      margin-right: 3px;
    }
  }
}

.user-dialog {
  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .title {
    border-bottom: 1px solid $color-border-light;
    color: #b5b5b5;
    font-size: $size-text;
    font-weight: 400;
    margin-top: 15px;
    padding: 5px 20px;
    text-transform: uppercase;
    @media (max-width: 360px) {
      font-size: 13px;
    }
  }

  .dialog-content {
    padding: 0;
  }

  .paper {
    border-radius: 0;
    box-shadow: none;
    margin: 25px;
  }

  .backdrop {
    background-color: #000;
    opacity: 0.8 !important;
  }

  .user-content {
    color: $color-text;
    font-size: 24px;
    padding: 20px;
  }

  .action-btn {
    border-radius: 0;
    box-shadow: none;
    color: #fff;
    padding: 8px 16px;
  }

  &.password {
    .paper {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      max-width: 300px;
      @media (max-width: 600px) {
        margin: 0;
        max-width: 100%;

        .change-password {
          margin-bottom: 100px;
          margin-top: 100px;
        }

        .action-btn {
          bottom: 35px;
          position: absolute;
          right: 25px;
        }
      }
    }

    .backdrop {
      background: #fff;
      opacity: 0.8 !important;
    }

    .dialog-title {
      padding-bottom: 0;
      position: relative;
    }

    .close-icon {
      cursor: pointer;
      font-size: 18px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .change-password {
      padding: 0 24px 16px;
    }

    .action-btn {
      align-items: center;
      border-radius: 3px;
      display: flex;
      height: 26px;
      justify-content: center;
      padding: 0;
      text-transform: capitalize;
    }
  }
}

#timezoneSelect {
  display: inline-block;
  margin: 0 10px;
  width: 80%;
}
