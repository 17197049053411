@import "./../../base/variables";

.new-template-page {
  .mobile-view {
    display: block;
  }
  @media(min-width: 960px) {
    margin-left: 60px !important;

    .desktop-view {
      display: block;
    }

    .desktop-title {
      margin-left: 20px;
    }

    .mobile-view {
      display: none;
    }

    .header-more {
      display: none;
    }

    .sub-menu {
      display: block;
    }

    .inner-content-wrapper {
      margin-top: 40px;
      padding: 0 100px;
      width: 600px;
    }

    .next-step {
      margin-left: 15px;
    }
  }
  @media(max-width: 960px) {
    .sub-menu {
      display: none;
    }
  }
}

.new-template {
  padding: 20px;

  .text-field {
    border-radius: 0;
    margin-bottom: 4px;
    width: 100%;
  }

  .label {
    color: $color-form-label;
    font-size: $size-label;
  }

  .input-wrapper {
    background-color: $color-surface-light;

    &:before {
      border-color: $color-border;
    }
  }

  .input {
    color: $color-input-text;
    font-size: $size-text;
    padding: 23px 12px 10px;
  }

  .multiline-textfield-wrapper {
    .text {
      align-items: center;
      border-bottom: 1px solid $color-border-light;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .title {
        color: #B5B5B5;
        font-size: $size-text;
        padding-left: 20px;
        text-transform: uppercase;
      }

      .right-icons {
        .material-icons {
          color: $color-primary;
        }

        .icon-button {
          padding: 4px 10px;
        }
      }
    }

    .multiline-textfield {
      background-color: #F9F9F9;
      box-sizing: border-box;
      min-height: 150px;
      padding: 16px;
    }

    .form-control::before {
      display: none;
    }

    .form-control::after {
      display: none;
    }
  }
}