@import "./../../base/variables";

.edit-button {
  color: #4b68f8;
  cursor: pointer;
  font-weight: 500;
}

.schedule-templates {
  padding-right: 20px;
  width: auto !important;
  padding-top: 0 !important;

  .sub-menu {
    display: none;
  }

  .title-section {
    display: flex;
    justify-content: space-between;

    .btn {
      border-radius: 3px;
      height: 40px;
    }
  }

  .desktop-view {
    display: none;
    margin-left: 42px;
    margin-top: 23px;
    width: 100%;

    .desktop-title {
      color: #131313;
      font-size: 24px;
      margin-bottom: 25px;
      margin-left: -18px;
    }
  }

  .schedule-templates-table {
    box-shadow: none !important;
    margin-left: -18px;

    .table-head {
      background-color: #f2f2f2;
      color: #131313;
      font-size: 14px;
      font-weight: 500;
      height: 40px;

      th {
        padding: 0 0 0 18px;
      }
    }

    .table-body tr {
      height: 50px;

      td {
        border-bottom: 1px solid #f2f2f2;
        color: #131313;
        font-size: 16px;
        padding: 0 0 0 18px;

        &:first-child {
          width: 22%;
          @media (max-width: 1100px) {
            width: 30%;
          }
        }

        &.app-column {
          .default {
            /*display: none;*/
          }
        }
      }

      .app-column {
        .default {
          background-color: $color-primary;
          border-radius: 4px;
          color: #fff;
          display: inline-block;
          height: 20px;
          margin-left: 100px;
          text-align: center;
          width: 76px;
        }
      }
    }
  }

  .mobile-view {
    display: block;
  }
  @media (min-width: 960px) {
    margin-left: 60px !important;
    position: relative;

    .desktop-view {
      display: block;
      padding-left: 225px;
    }

    .mobile-view {
      display: none;
    }

    .header-more {
      display: none;
    }

    .sub-menu {
      bottom: 0;
      display: block;
      position: fixed;
      top: 0;
    }
  }

  .single-listitem {
    border-bottom: 1px solid #e1e1e1;
    color: $color-text;

    .icon-button {
      padding: 8px;
    }

    .right-content {
      align-items: center;
      display: flex;
    }

    .default {
      align-items: center;
      background-color: #d7f205;
      border: none;
      border: 1px solid #d7f205;
      color: #067302;
      display: flex;
      font-size: $size-label;
      height: 24px;
      justify-content: center;
      width: 24px;
    }

    .number {
      align-items: center;
      border: 1px solid #d9d9d9;
      border-left: 4px solid #d9d9d9;
      color: #b5b5b5;
      display: flex;
      font-size: $size-label;
      height: 24px;
      justify-content: center;
      width: 24px;

      &.active {
        border: 1px solid #d7f205;
      }
    }
  }

  .fab {
    bottom: 76px;
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.26));
    height: 45px;
    position: absolute;
    right: 20px;
    width: 45px;
    z-index: 1102;

    .material-icons {
      color: #fff;
      font-size: 38px;
    }
  }

  .disabled,
  .disabled:hover {
    background-color: #ccc;
  }
}

.edit-schedule {
  width: calc(100% - 60px) !important;

  @media (max-width: 650px) {
    width: calc(100% - 0px) !important;
  }

  .desktop-title {
    margin-left: 0 !important;
  }

  .inner-content-wrapper {
    margin-top: 40px;

    .content {
      margin: 0 auto;
    }
  }

  .content {
    width: 90%;
    @media (max-width: 650px) {
      width: 100% !important;
    }

    .switches-title {
      align-items: center;
      border-bottom: 1px solid $color-border-light;
      color: #b5b5b5;
      display: flex;
      font-size: $size-text;
      height: 40px;
      justify-content: flex-start;
      padding: 0 20px;
      text-transform: uppercase;
    }
  }

  .time-interval {
    border-bottom: 1px solid $color-border-light;
    color: $color-text;
    font-size: $size-menu;
    padding: 14px 20px;
  }

  .switch-control-two {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    padding: 0 20px;
  }

  .switch-control {
    align-items: center;
    border-bottom: 1px solid $color-border-light;
    display: flex !important;
    justify-content: space-between;
    padding: 0 20px;

    &.reminder {
      margin-bottom: 40px;
      padding: 8px 20px;
    }

    .sub-title {
      color: #b5b5b5;
      font-size: $size-text;
      text-transform: uppercase;
    }

    .material-icons {
      vertical-align: middle;
      color: #97969c;
    }
  }

  .switch-right {
    .material-icons {
      vertical-align: middle;
      color: #97969c;
    }
  }

  .switch-on {
    display: flex;
    justify-content: center;
    margin-top: 65px;
    padding: 0 20px;
  }

  .schedule-list {
    .rc-swipeout-btn {
      color: #fff;
      font-size: 13px;
      text-transform: uppercase;

      &.swipeout-left {
        background-color: #ff5454;
      }

      &.swipeout-right {
        background-color: #63b6ff;
      }
    }
  }

  .switch-off {
    color: #b5b5b5;
    font-size: 14px;
    margin-top: 12px;
    padding: 0 20px;
  }

  .add-followup {
    border-color: $color-primary;
    color: $color-primary;
    font-size: $size-text;
    height: 40px;
    max-width: 170px;
    text-align: center;
    width: 100%;
  }

  .single-listitem {
    border-bottom: 1px solid #e1e1e1;
    color: $color-text;
  }

  .limit-alert {
    color: #b5b5b5;
    font-size: 14px;
    margin-top: 12px;
    padding: 0 20px;
  }
}

.MuiTooltip-tooltip {
  background-color: rgba(97, 97, 97, 1);
}

.assign-template-btn {
  padding: 3px 15px !important;
  color: white !important;
}

.assign-template-modal {
  .assign-template-modal-heading {
    margin: 0px;
    padding: 0px;
  }

  .assign-modal-label {
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
  }

  .assign-folder-btn {
    color: white !important;
  }

  .folders-heading-container {
    border-bottom: 1px solid #e1e1e1;
  }

  .assign-template-folder-container {
    border-bottom: 1px solid #e1e1e1;
  }

  .assign-template-folder-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;

    label {
      font-weight: 600;
    }

    .first-element {
      margin-left: 40px;
    }
  }

  .schedule-services-container {
    margin-left: 55px;
    border-left: 1px solid #e1e1e1;
    position: relative;

    .schedule-service-list {
      position: relative;
      border: 1px solid #e1e1e1;
      border-left: none;
      padding-top: 10px;
    }
  }

  .vertical-line {
    border-left: 1px solid #e1e1e1;
    height: 20px;
    position: absolute;
    top: -20px;
  }

  .horizontal-line {
    border-top: 1px solid #e1e1e1;
    width: 15px;
    position: absolute;
    top: 30px;
  }

  .small-label {
    font-size: 0.9rem;
  }
}
